body{
  &.landingindark{

    background: #22252C;
    color: #fff;

    h1,h2,h3,h4,h5,h6,
    .h1,.h2,.h3,.h4,.h5,.h6{
      font-family: 'Bebas Neue', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      line-height:1.2;
    }

  }
}

.bg-fade-orange-purple{
  color: #fff;
  background: linear-gradient(90deg, #EB6F2E 0%, #84206E 100%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.7);
}
