
.sitedebug-breakpoints{
  position:absolute;
  font-size:10px;
  color:black;
  background:yellow;
  top:0;
  left:0;
  padding:2px;
  z-index:1050;
}
.sitedebug-breakpoints div{
  padding:2px;
}
.sitedebug-xs,.sitedebug-sm,.sitedebug-md,.sitedebug-lg,.sitedebug-xl,.sitedebug-xxl{
  display:none;
}
.sitedebug-xs{
  @include media-breakpoint-up(xs){
    display:inline-block;
  }
}
.sitedebug-sm{
  @include media-breakpoint-up(sm){
    display:inline-block;
  }
}
.sitedebug-md{
  @include media-breakpoint-up(md){
    display:inline-block;
  }
}
.sitedebug-lg{
  @include media-breakpoint-up(lg){
    display:inline-block;
  }
}
.sitedebug-xl{
  @include media-breakpoint-up(xl){
    display:inline-block;
  }
}
.sitedebug-xxl{
  @include media-breakpoint-up(xxl){
    display:inline-block;
  }
}
