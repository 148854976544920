/* stylelint-disable scss/dollar-variable-default */
@import "../node_modules/bootstrap/scss/functions";

/* stylelint-enable */
@import "theme/colours";
@import "theme/settings";
@import "theme/landingindark";

@import "utility/modules";
@import "utility/theme";




body{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
main{
  flex: 1;
}


/* text */
b{
  font-weight:$font-weight-bold;
}
.font-weight-boldest{
  font-weight:$font-weight-boldest;
}


.xsmall {
  @include font-size($xsmall-font-size);
}
.large {
  @include font-size($large-font-size);
}
.xlarge {
  @include font-size($xlarge-font-size);
}
.medium {
  @include font-size($medium-font-size);
}
.small-md {
  @include media-breakpoint-up(md){
    @include font-size($small-font-size);
  }
}
.small-lg {
  @include media-breakpoint-up(lg){
    @include font-size($small-font-size);
  }
}
.small-xl {
  @include media-breakpoint-up(xl){
    @include font-size($small-font-size);
  }
}


/* utility */
.lead{
  max-width:25em;
  line-height:($spacer * 2);
  &.lead-auto{
    max-width:none;
  }
}

.max-width-none{
  max-width: none !important;
}

.max-width-md-25{
  @include media-breakpoint-up(md){
    max-width:25em !important;
  }
}
.max-width-md-12{
  @include media-breakpoint-up(md){
    max-width:12em !important;
  }
}
a.overlay{
  z-index:999;
  position:relative;
}



/* navbar */
.navbar{
  .navbar-logo{
    width:156px;
    height:auto;
    @include media-breakpoint-up(lg){
      width:190px;
    }
  }
  .navbar-toggler{
    margin-right:-1rem;
    border:0 !important;
  }
  .navbar-toggler-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
 .nav-item{
    padding-top:(.8125 * $spacer);
    padding-bottom:(.8125 * $spacer);

    a, span{
      padding-top:(.1875 * $spacer);
      padding-bottom:(.1875 * $spacer);
      padding-left:(1.875 * $spacer);
      &.active{
        border-left:(.1875 * $spacer) solid $primary;
        padding-left:(1.6875 * $spacer);
        font-weight:$font-weight-bold;
      }
    }

    &.nav-item-contact{
      position:absolute;
      bottom:1.5rem;
      left:1rem;
      right:1rem;
      padding:0;
      .nav-link{
        font-size:1rem;
        padding-left:0;
        @include media-breakpoint-up(md){
          font-size: .875rem;
        }
      }

      @include media-breakpoint-up(sm){

      }

      @include media-breakpoint-up(md){
        position:static;
        padding:0;
        .nav-link{
          font-size: .875rem;
        }
      }

      @include media-breakpoint-up(lg){
        margin-left: ($spacer * 3.25);
      }


    }
    @include media-breakpoint-up(md){
      margin-left:($spacer * .875);
      padding-top:0;
      padding-bottom:0;
      a, span{
        padding-top:(1.25 * $spacer);
        padding-bottom:(1.25 * $spacer);
        padding-left:(.25 * $spacer);
        padding-right:(.25 * $spacer);
        &.active{
          border-left:0;
          border-bottom:(.25 * $spacer) solid $primary;
          padding-bottom:(1 * $spacer);
          padding-left:(.25 * $spacer);
          padding-right:(.25 * $spacer);
          font-weight:$font-weight-bold;
        }
      }
    }
    .nav-link{
      font-size: 1.5rem;
      @include media-breakpoint-up(md){
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    overflow-y: auto;
    visibility: hidden;
    background-color: var(--secondary);
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;

    .navbar-logo-menu{
      margin-left:1rem;
      margin-bottom:1px;
    }
    .navbar-toggler{
      &.navbar-toggler-close{
        margin-right:0;
      }
    }
    .navbar-toggler-close{
      margin-right:0;
      padding:1.25rem;
      .navbar-toggler-icon {
        width: 1rem;
        height: 1rem;
      }
    }

    &.open {
      visibility: visible;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
      z-index:999;
      .navbar-nav{

      }
    }
  }
}

.list-tags{
  li {
    a{
      &:hover{
        background:$primary !important;
        color:$white !important;
        text-decoration:none;
        svg{
          color:$white;
        }
      }
    }
  }
}



/* events */
.event-vendor{
  .svg-vendor-logo{
    color:$dark;
    height:($spacer * 1.875);
    width:auto;
    max-width:($spacer * 7);
    margin-bottom: ($spacer * .5)
  }
}
.event-overview{
  margin-bottom:$spacer;
}
.event-description{
  li{
    margin-bottom:$spacer;
  }
}

/* cards */
.card{
  h3{
  font-family:$font-family-base;
  @include font-size($font-size-base * 1.125);
}
  p, li{
  @include font-size($font-size-base * .875);
}
  p:last-child {
    margin-bottom: 0;
  }
  h3{
    a{
      color:$black;
    }
  }
  &.bg-primary{
    h3{
      a{
        color:$white;
      }
    }
  }
  &.bg-dark{
    h3{
      a{
        color:$white;
      }
    }
  }
}



.card-event,.card-feature {

  .card-header{
    padding:0;
    .svg-vendor-logo{
      color:$white;
      height:($spacer * 2.875);
      width:auto;
      max-width:($spacer * 10);
      padding:($spacer * .625) ($spacer * .875);
    }
  }

  .card-event-datetime,.card-cta{
    @include font-size($font-size-base * .875);
    font-weight:$font-weight-bold;
    margin-top:.1rem
  }

  .svg-vendor-logo{
    color:$dark;
    height:($spacer * 2.875);
    width:auto;
    max-width:($spacer * 6);
    margin-bottom: ($spacer * .5)
  }


  &:hover,&:focus{
    box-shadow: 0 0.25rem 0.75rem rgba(2, 2, 2, 0.24) !important;
    .card-cta{
      color:$primary !important;
      text-decoration:underline;
    }
  }

  .card-title{
    a{
      &:hover{
        text-decoration:none;
      }
    }
  }

  &.bg-primary{

    a.text-light{
      &:hover,&:focus{
        color:$white !important;
      }
    }
    &:hover,&:focus{
      .card-cta{
        color:$black !important;
      }
      .svg-nav-icon{
        color:$black !important;
      }
    }
    .svg-vendor-logo{
      color:$white;
    }

    .svg-nav-icon{
      color:$white;
    }

  }

  .card-logo-m{
    .svg-vendor-logo{
      max-width:($spacer * 10);
    }
  }

}

.row-events{
  .col{
    margin-bottom:($spacer * 1.25);
    @include media-breakpoint-up(md){
      margin-bottom:($spacer * 2.5);
    }
  }
}

.row-logos {
  margin-right:-10px;
  margin-left:-10px;
  .col{
    margin-bottom: 1.125rem !important;
    max-width:9.375rem;
  }
  .card-logo {
    padding:1rem;
    margin:0 0.625rem;
    height:7.5rem;
    @include media-breakpoint-up(xl){
      height:7.5rem;
    }
    a{
      display:flex;
      height:100%;
      width:100%;
      color:$gray-600;
      &:hover{
        color:$primary;
      }
    }

  }
  &.row-logos-big {
    .col{
      max-width:11.375rem;
    }
    .card-logo{
      height:auto;
    }
  }
}

.row-vendors{
  .col{
    margin-bottom:($spacer * 1.25);
    @include media-breakpoint-up(md){
      margin-bottom:($spacer * 2.5);
    }
  }
}

.card-vendor {
  .card-header{
    padding:0;
  }
  .svg-vendor-logo{
    color:$dark;
    height:($spacer * 3.875);
    width:auto;
    max-width:($spacer * 10);
    padding:($spacer * 1.625) ($spacer * .875) 0;
  }
  &:hover,&:focus{
    box-shadow: 0 0.25rem 0.75rem rgba(2, 2, 2, 0.24) !important;
  }
  .card-footer{
    a{
      position:relative;
      z-index:2;
    }
  }
}
.vendor-logo{
    height:5.75rem;
    width:5.75rem;
  @include media-breakpoint-up(md){
    width:8rem;
    height:8rem;
  }
  padding:0 ($spacer * 1.25);
  background: $white;
  margin-bottom:(-1 * $spacer);
  svg{
    width:100%;
  }
}

.img-round-40{
  border-radius:20px;
  height:40px;
  width:40px;
}
.img-round-60{
  border-radius:30px;
  height:60px;
  width:60px;
}
.img-round-80{
  border-radius:40px;
  height:80px;
  width:80px;
}
.img-team-profile{
  height:80px;
  width:80px;
}

.img-hero{
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height:15rem;
  @include media-breakpoint-up(sm){
    height:18rem;
  }
  @include media-breakpoint-up(lg){
    height:30rem;
  }
  @media (min-width: 1299.98px){
    height:35rem;
  }
  @media (min-width: 1499.98px){
    height:38rem;
  }
}

.bg-gradient-gray{
  background: #FCFCFC linear-gradient(180deg, #FCFCFC 0%, #EFEFEF 100%) repeat-x !important;
}
.bg-ideas-right{
  background:transparent url("/img/hero/illustration-lightbulbs-lg.png") no-repeat top right;
}

.border-bottom-1{
  border-bottom:1px solid $gray-200;
}

.vendor-location, .vendor-link{
  @include font-size($xsmall-font-size);
  @include media-breakpoint-up(md){
    @include font-size($medium-font-size);
  }
}



.svg-nav-icon{
  color:$primary;
  margin-right:$spacer * .375;
}
.btn-primary{
  .svg-nav-icon{
    color:$white;
  }
}
.jumbotron{
  .jumbotron-hero{
    img{
      margin:2rem auto 0;
    }
    img{
      @include media-breakpoint-up(md){
        margin: ($spacer * 4.375)  ($spacer * (5.4375 / 2)) 0 0;
      }
      @include media-breakpoint-up(lg){
        margin: ($spacer * 4.375) ($spacer * 6) 0 ($spacer * (5.4375 / 2));
      }
    }
  }
  .svg-cnx-text{
    margin-top:3rem;
    max-width:238px;
    @include media-breakpoint-up(md){
      margin-top:0;
      max-width:none;
    }
  }
}


.social-icon{
  &:hover,:focus{
    color:$primary;
  }
  &.social-icon-twitter{
    color:#1d9bf0;
    &:hover,:focus{
      color:$primary;
    }
  }
  &.social-icon-linkedin{
    color:#0a66c2;
    &:hover,:focus{
      color:$primary;
    }
  }
}

/* brand */
.cnx-logo-primary{
  color:$primary;
}

.cnx-logo-secondary{
  color:$secondary;
}
.bg-dark{
  .cnx-logo-secondary{
    color:$white;
  }
}
.cnx-logo-text-inline{
  font-weight: $font-weight-bold;
  i{
    font-style:normal;
    color:$primary;
  }
}




/* forms */
.form-control{
  background-clip: border-box;
}

.form-signup{
  form{
    .form-control{
      border-color:$primary;
    }
  }
  &.form-signup-box{
    margin-right:-20px;
    margin-left:-20px;
    @include media-breakpoint-up(sm){
      margin-right:0;
      margin-left:0;
    }
    form{
      @include media-breakpoint-up(md){
        padding-top:($spacer * 1.5);
        padding-bottom:($spacer * 1.5);
      }
    }
    @include media-breakpoint-up(md){
      .h4{
        margin-left:($spacer * 3.125);
      }
      form{
        padding-top:($spacer * 3);
        padding-bottom:($spacer * 3);
        margin-right:($spacer * 3.125);
      }
    }
  }

}

.btn{
  line-height: ($spacer* 1.25);
}
.btn-mobile-100{
  @include media-breakpoint-down(sm){
    width:100%;
  }
}

.modal-header-custom{
  position:relative;
  min-height:3.5rem;
  .close{
    position:absolute;
    top:0;
    right:0;
    color:$gray-700;
    padding:1rem;
    &:hover{
      color:$primary;
    }
  }
}

.custom-select {
  background: #fff url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.2656 8L12 12.8229L6.73436 8L5 9.58854L12 16L19 9.58854L17.2656 8Z' fill='%23020202'/%3E%3C/svg%3E") no-repeat right 0.75rem center/24px 24px;
  background-position-x:calc(100% - 4px)
}

.transcript{
  .transcript-timestamp{
    display:none;
  }
  .transcript-speaker{
    @include font-size(1.125rem);
    margin-top:($spacer * 2);
    margin-bottom: (1.125 * $spacer);
    b{
      padding: (.125 * $spacer) (.5 * $spacer);
      display: inline-block;
    }
    &.s1{
      border-bottom:1px solid $primary;
      b{
        background:$primary;
        color:$light;
      }
    }
    &.s2{
      border-bottom:1px solid $dark;
      b{
        background:$dark;
        color:$light;
      }
    }
  }

  .transcript-item{
  }
}

blockquote{
  position: relative;
  &.blockquote-quote-start{
    &:before{
      content: '“';
      font-size:($spacer * 4);
      color: var(--primary);
      position: absolute;
      top:0;
      left:($spacer * 1);
      @include media-breakpoint-up(md){
        font-size:($spacer * 7);
        left:($spacer * 2);
        top:(-$spacer * .5);
      }
    }
    p,footer{
      margin-left:($spacer * 3);
      margin-right:($spacer * 1);
      @include media-breakpoint-up(md){
        margin-left:($spacer * 4);
        margin-right:($spacer * 4);
      }
    }
  }
}


.container-article{
  li{
    margin-bottom:($spacer * .5)
  }
}

.ribbon{
  position:relative;
  margin-left: (-$spacer * 2);
  @include media-breakpoint-up(md){
    margin-left: (-$spacer * 3.5);
  }
  span{
    padding: ($spacer * .5) ($spacer * 2);
    background:$primary;
    color:$light;
    display:inline-block;
    font-weight:$font-weight-bolder;
    position:relative;
    height:($spacer * 2.5);
    @include media-breakpoint-up(md){
      &:before{
        content:"";
        bottom:-($spacer * .75);
        position:absolute;
        display:block;
        border-style:solid;
        border-color: #C83316 transparent transparent transparent;
        left: 0;
        border-width: ($spacer * .75) 0 0 ($spacer * 1.5);
      }
    }
    &:after{
      position:absolute;
      top:0;
      right:-($spacer * 1.375);
      content:"";
      display:block;
      width: ($spacer * 1.375);
      height: 0px;
      border-top: ($spacer * 1.25) solid $primary;
      border-bottom: ($spacer * 1.25) solid $primary;
      border-right: ($spacer * 1.375) solid transparent;
    }
  }
}

.help-block{
  list-style:none;
  padding-left:0;
  margin-bottom:0;
}


/*
initial load is set to visibility: hidden;opacity: 0; inline in template to avoid fouc
this declaration goes last so it's applied when all other styles have loaded
*/

html {
  visibility: visible;
  opacity: 1;
}
